:root {
  --header-height: 4rem;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
}

html {
  height: 100vh;
  overflow: hidden;
}
body {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  overflow: auto;
  height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
}

#main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 1em;
}

#header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1em;
  width: 100vw;
}

#body {
  position: absolute;
  top: var(--header-height);
  left: 0;
  right: 0;
  bottom: 0;
}
