@import '@shoelace-style/shoelace/dist/themes/light.css';
@import '@shoelace-style/shoelace/dist/themes/dark.css';

@import './colors.css';
@import './fonts.css';
@import './layout.css';
@import './development/index.css';
@import './components.css';
@import './utilities.css';

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  height: 100vh;
  overflow: hidden;
}
body {
  margin: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  overflow: auto;
  height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
}

* {
  box-sizing: border-box;
}
